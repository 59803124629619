import React, { useState, useContext } from "react";
import "./Header.css";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { LoginContext } from "../../../../App";
import { Toaster, toast } from "sonner";
import { FaTiktok } from "react-icons/fa";
import { FaVideo } from "react-icons/fa";
import { GiSoundOn } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";
import { TbApi } from "react-icons/tb";
import CommonLoader from "../../../../loader/CommonLoader";

const Header = () => {
  const { setUser } = useContext(LoginContext),
    userName = localStorage.getItem("userName"),
    userRole = localStorage.getItem("userRole"),
    [showResetModal, setShowResetModal] = useState(false),
    [newPassword, setNewPassword] = useState(""),
    [currentPassword, setCurrentPassword] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [validationError, setValidationError] = useState(""), // State for validation error messag,
    location = useLocation();

  const isActive = (path) => location.pathname === path;

  const getLinkClass = (path) => {
    return `text-decoration-none ${
      isActive(path) ? "dashboard-header active-link" : "dashboard-link"
    }`;
  };

  const activeLinkStyle = {
    backgroundColor: "#f5f5f5",
    color: "#262626",
    padding: "5px",
    "border-radius": "10px",
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axiosInstance.post(
        `/api/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.removeItem("token");
      localStorage.clear();
      setUser({});
      setIsLoading(false);
    } catch (error) {
      // check for if token is expired or session logout
      if (error.response.status === 401) {
        window.location.href = "/";
      }
      console.error("Logout error:", error);
      localStorage.removeItem("token");
      localStorage.clear();
      setIsLoading(false);
    }
  };

  const handleResetPassword = async () => {
    // Validation check for empty fields
    if (!currentPassword || !newPassword) {
      setValidationError("Please fill out both fields.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axiosInstance.post(
        `/api/change-password`,
        {
          current_password: currentPassword,
          new_password: newPassword,
          new_password_confirmation: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Password updated successfully");
      setShowResetModal(false);
      setNewPassword("");
      setCurrentPassword("");
      setValidationError("");
    } catch (error) {
      console.error("Password change error:", error);
    }
  };

  return (
    <div className="sticky-element">
      <header>
        <div className="headerContainer d-flex align-items-center justify-content-between p-2">
          <div className="navLink">
            <ul className="d-flex nav-list m-0 list-unstyled">
              <li className="d-flex align-items-center">
                <Link
                  to="/tiktokAccounts"
                  className={getLinkClass("/tiktokAccounts")}
                  style={isActive("/tiktokAccounts") ? activeLinkStyle : {}}
                >
                  <FaTiktok className="me-2" />
                  Tiktok Accounts
                </Link>
              </li>
              <li className="d-flex align-items-center">
                <Link
                  className={getLinkClass("/tiktokVideos")}
                  style={isActive("/tiktokVideos") ? activeLinkStyle : {}}
                  to="/tiktokVideos"
                >
                  <FaVideo className="me-2" />
                  Tiktok Posts
                </Link>
              </li>
              <li className="d-flex align-items-center">
                <Link
                  className={getLinkClass("/tiktok-sounds")}
                  style={isActive("/tiktok-sounds") ? activeLinkStyle : {}}
                  to="/tiktok-sounds"
                >
                  <GiSoundOn className="me-2" />
                  Tiktok Sounds
                </Link>
              </li>

              {localStorage.getItem("userRole") === "super-admin" && (
                <li className="d-flex align-items-center">
                  <Link
                    to="/dailyApiHits"
                    className={getLinkClass("/dailyApiHits")}
                    style={isActive("/dailyApiHits") ? activeLinkStyle : {}}
                  >
                    <TbApi className="me-2" />
                    Daily API Hits
                  </Link>
                </li>
              )}

              {localStorage.getItem("userRole") === "client" && (
                <li className="d-flex align-items-center">
                  <Link
                    className={getLinkClass("/add-tiktoks")}
                    to="/add-tiktoks"
                    style={isActive("/add-tiktoks") ? activeLinkStyle : {}}
                  >
                    <FaTiktok className="me-2" />
                    Add Tiktoks
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <div className="userLoginDetails">
            {isLoading ? (
              <CommonLoader />
            ) : (
              <div>
                <Dropdown className="me-4 fs-4  fw-bold p-1 rounded brand">
                  <Dropdown.Toggle
                    variant="success"
                    className="fw-bold"
                    id="dropdown-basic"
                  >
                    {userName}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Toaster richColors position="top-center" />
                    <Dropdown.Item onClick={() => setShowResetModal(true)}>
                      Reset password
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Modal
                  show={showResetModal}
                  onHide={() => {
                    setShowResetModal(false);
                    setValidationError("");
                  }}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group controlId="formCurrentPassword">
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter current password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="formNewPassword">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </Form.Group>
                    {validationError && (
                      <p className="text-danger">{validationError}</p>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowResetModal(false);
                        setValidationError("");
                      }}
                    >
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleResetPassword}>
                      Save Password
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
