import React, { useEffect, useState, useRef, useMemo } from "react";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import TikTokPostFilter from "./component/TikTokPostFilter";
import { FaLink } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Badge from "react-bootstrap/Badge";
import { Popover, ArrowContainer } from "react-tiny-popover";
import { LuFilter } from "react-icons/lu";
import { Button } from "react-bootstrap";
import { colors } from "../../../utils/constants";
import moment from "moment/moment";
import FullscreenWrapper from "../common-components/FullScreenWrapper";
import { Line } from "react-chartjs-2";

const TikTokVideosIndex = () => {
  const [videos, setVideos] = useState([]),
    [tiktokAccounts, setTikTokAccounts] = useState([]),
    [isLoading, setLoading] = useState(false),
    [error, setError] = useState(null),
    [pagination, setPagination] = useState([]),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [filters, setFilters] = useState({
      tiktok_id: null,
      views_from: "",
      views_to: "",
      likes_from: "",
      likes_to: "",
      comments_from: "",
      comments_to: "",
      shares_from: "",
      shares_to: "",
      saved_from: "",
      saved_to: "",
      tags: [],
      from: "",
      to: "",
      sort_by: "create_time",
      order_by: "desc",
    }),
    [activeTagsList, setActiveTagsList] = useState([]),
    [record, setRecord] = useState({
      visible: false,
      tag: [],
    }),
    [stats, setStats] = useState({
      labels: [],
      views: [],
      interactions: [],
      engagements: [],
    }),
    [isPopoverOpen, setIsPopoverOpen] = useState(false),
    clickMeButtonRef = useRef(),
    [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const fetchTikTokVideos = async (page = 1) => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.post(
        `/api/tiktok/user/videos?page=${page}`,
        filters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const videoData = response.data.data;
      setVideos(videoData);
      const { pagination: apiPagination } = response.data;
      setPagination(apiPagination);
      setCurrentPage(apiPagination.current_page);
      setTotalPages(apiPagination.last_page);
      setTikTokAccounts(response.data.tiktok_users);

      const localLabels = [],
        valueViews = [],
        valueInteractions = [],
        valueEngagement = [],
        totalViews = [],
        totalInteractions = [],
        totalEngagement = [],
        count = [];

      response.data.averages_by_date.forEach((i) => {
        localLabels.push(moment(i.date).format("DD MMM"));
        valueViews.push(i.avg_views);
        valueInteractions.push(i.avg_interactions);
        valueEngagement.push(i.avg_engagements);
        totalViews.push(i.total_views);
        totalInteractions.push(i.total_interactions);
        totalEngagement.push(i.total_engagements);
        count.push(i.count);
      });

      setStats({
        labels: localLabels,
        views: valueViews,
        interactions: valueInteractions,
        engagements: valueEngagement,
        totalViews: totalViews,
        totalInteractions: totalInteractions,
        totalEngagement: totalEngagement,
        count: count,
      });

      setLoading(false);
    } catch (err) {
      setVideos([]);
      setError("Failed to fetch TikTok Videos");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTikTokVideos(currentPage);
  }, [currentPage, filters]);

  useEffect(() => {
    getActiveTags();
  }, []);

  const getActiveTags = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(`/api/active-tags`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const localActiveTags = response.data.tags?.map((tag) => ({
          label: tag.title,
          value: tag.id,
        }));
        setRecord({
          ...record,
          visible: true,
        });
        setActiveTagsList(localActiveTags);
      }
    } catch (error) {
      console.error("Error fetching record for edit:", error);
    }
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  const getActiveBadges = (video) => {
    return video?.tag?.map((t, i) => (
      <Badge
        bg="success"
        key={i}
        style={{ display: "block", marginBottom: "5px" }}
      >
        {t.title}
      </Badge>
    ));
  };

  const sortedPosts = useMemo(() => {
    if (!sortConfig.key) return videos;

    const sortedData = [...videos];
    sortedData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  }, [videos, sortConfig]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return null;
  };

  const generateChartForStatistics = (label, content, label2, content2, label3, content3) => {
    const data = {
      labels: stats.labels,
      datasets: [
        {
          label: label,
          data: content,
          backgroundColor: colors.black,
          borderColor: colors.black,
          borderWidth: 1,
          pointRadius: 2,
          pointBackgroundColor: colors.black,
          tension: 0.2,
          yAxisID: "y",
        },
        {
          label: label2,
          data: content2,
          backgroundColor: colors.gray,
          borderColor: colors.gray,
          borderWidth: 1,
          pointRadius: 2,
          pointBackgroundColor: colors.gray,
          tension: 0.2,
          yAxisID: "y",
        },
        {
          label: label3,
          data: content3,
          backgroundColor: colors.silvar,
          borderColor: colors.silvar,
          borderWidth: 1,
          pointRadius: 2,
          pointBackgroundColor: colors.silvar,
          tension: 0.2,
          yAxisID: "z",
        },
      ],
    };
    return data;
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: false,
        text: "",
        font: {
          size: 12,
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Averages / Totals",
        },
      },
      z: {
        type: "linear",
        display: true,
        position: "right",
        title: {
          display: true,
          text: "Count",
        },
        grid: {
          drawOnChartArea: false,
        },
        min: 0,
        max: 50,
      },
    },
  };

  return (
    <div className="clientLists userLists">
      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">
            <div style={{ display: "flex" }}>
              <h1 className="bold fs-4 mb-0 me-4">TikTok Posts</h1>
              <div>
                <Popover
                  isOpen={isPopoverOpen}
                  positions={["bottom"]}
                  padding={10}
                  onClickOutside={() => setIsPopoverOpen(false)}
                  ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
                  content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      arrowColor={"blue"}
                      arrowSize={10}
                      arrowStyle={{ opacity: 0.7 }}
                      className="popover-arrow-container"
                      arrowClassName="popover-arrow"
                    >
                      <div
                        style={{ backgroundColor: "#fff" }}
                        className="d-flex align-content-center align-items-center"
                      >
                        <TikTokPostFilter
                          tiktokUsers={tiktokAccounts}
                          activeTagsList={activeTagsList}
                          onFilterChange={handleFilterChange}
                        />
                      </div>
                    </ArrowContainer>
                  )}
                >
                  <Button
                    variant="primary"
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                  >
                    Filters
                  </Button>
                </Popover>
              </div>
            </div>

            {/* Charts */}
            <div className="chart-wrapper">
              <div className="row mt-3 p-0">
                <div className="col-4 p-0">
                  <FullscreenWrapper>
                    <Line height={175}
                      data={generateChartForStatistics("Average Views", stats.views, "Total Views", stats.totalViews, "Count", stats.count)}
                      options={options}
                    />
                  </FullscreenWrapper>
                </div>
                <div className="col-4 p-0">
                  <FullscreenWrapper>
                    <Line height={175}
                      data={generateChartForStatistics(
                        "Average Interactions",
                        stats.interactions,
                        'Total Interactions',
                        stats.totalInteractions,
                        'Count',
                        stats.count
                      )}
                      options={options}
                    />
                  </FullscreenWrapper>
                </div>
                <div className="col-4 p-0">
                  <FullscreenWrapper>
                    <Line height={175}
                      data={generateChartForStatistics(
                        "Average Engagements",
                        stats.engagements,
                        'Total Engagements',
                        stats.totalEngagement,
                        'Count',
                        stats.count
                      )}
                      options={options}
                    />
                  </FullscreenWrapper>
                </div>
              </div>
            </div>

            <div className="details mt-4">
              <table className="user-table">
                <thead>
                  <tr>
                    <th>Account Name</th>
                    <th>Tiktok Username</th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("video_id")}
                    >
                      Video ID &nbsp;
                      {getArrow("follower_count")}
                    </th>
                    <th>Video Link</th>
                    <th>Video Creation Time</th>
                    <th>Sound ID</th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("views")}
                    >
                      Views &nbsp;
                      {getArrow("views")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("likes")}
                    >
                      Likes &nbsp;
                      {getArrow("likes")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("comments")}
                    >
                      Comments &nbsp;
                      {getArrow("comments")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("shares")}
                    >
                      Shares &nbsp;
                      {getArrow("shares")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("saved")}
                    >
                      Saved &nbsp;
                      {getArrow("saved")}
                    </th>
                    <th>Tags</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedPosts.length > 0 ? (
                    sortedPosts.map((video, index) => (
                      <tr key={index}>
                        <td>{video.client}</td>
                        <td>{video.tiktok_nickname}</td>
                        <td>
                          <Link
                            to={`/Tiktok-video-statistics/${video?.video_id}`}
                          >
                            <span data-tooltip-id="noedit-button">
                              <FaExternalLinkAlt />
                            </span>
                            <ReactTooltip
                              id="noedit-button"
                              place="bottom"
                              content="go to video statistics"
                            />
                          </Link>
                        </td>
                        <td>
                          <a
                            href={video.video_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaLink />
                          </a>
                        </td>
                        <td>{video.create_time}</td>
                        <td>
                          <Link
                            to={`/Tiktok-sound-statistics/${video?.sound_id}`}
                          >
                            {video?.sound_id}
                          </Link>
                        </td>
                        <td>{video.views}</td>
                        <td>{video.likes}</td>
                        <td>{video.comments}</td>
                        <td>{video.shares}</td>
                        <td>{video.saved}</td>
                        <td>{getActiveBadges(video)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10">No videos found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {videos.length > 0 && totalPages > 1 && (
              <div className="pagination">
                <PaginationComponent
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  pageStart={pageStart}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TikTokVideosIndex;
