import React, { useEffect, useState, useContext } from "react";
import { Bar, Line } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import DaysFilter from "./DaysFilter";
import { debounce } from "lodash";
import ErrorPage from "../../common-components/error";
import moment from "moment";
import subDays from "date-fns/subDays";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LimitFilter from "./LimitFilter";
import { LoginContext } from "../../../../App";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TikTokEngagementMetrics = () => {
  const { selectedDays, setSelectedDays } = useContext(LoginContext),
    [selectedLimit, setSelectedLimit] = useState(10),
    [engagementData, setEngagementData] = useState([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null),
    navigate = useNavigate();

  const debouncedFetchEngagementMetrics = debounce(async (days) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");

    try {
      const response = await axiosInstance.post(
        "/api/dashboard/engagement-metrics",
        { days: null, from, to, limit: selectedLimit },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setEngagementData(response.data.data);
      setLoading(false);
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Failed to fetch TikTok engagement metrics"
      );
      setLoading(false);
    }
  }, 300);

  useEffect(() => {
    debouncedFetchEngagementMetrics(selectedDays);
  }, [selectedDays, selectedLimit]);

  const generateChartData = () => {
    const labels = engagementData.map((item) => item.date);
    const data = {
      labels,
      datasets: [
        {
          label: "Total Views",
          data: engagementData.map((item) => item.views),
          backgroundColor: "#FF6384",
          borderColor: "#FF6384",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "#FF6384",
        },
        {
          label: "Total Interactions",
          data: engagementData.map((item) => item.interactions),
          backgroundColor: "#9966FF",
          borderColor: "#9966FF",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "#9966FF",
        },
      ],
    };
    return data;
  };
  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
    onClick: (event, elements) => handleChartClick(event, elements),
  };

  const handleChartClick = (event, elements) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      const videoId = engagementData[index].video_id;
      navigate(`/Tiktok-video-statistics/${videoId}`);
    }
  };

  const onChangeLimit = (value) => {
    setSelectedLimit(value);
  };
  return (
    <div className="engagement-metrics-container mb-5">
      <div className="filter-container p-3">
        <div class="row">
          <div class="col-md-6">
            <DaysFilter selectedDays={selectedDays} onChange={onChange} />
          </div>
          <div class="col-md-6 limit-filter">
            <LimitFilter
              selectedLimit={selectedLimit}
              onChange={onChangeLimit}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : error ? (
        <div className="error-overlay">
          <ErrorPage refetch={debouncedFetchEngagementMetrics} />
        </div>
      ) : (
        <div className="chart-wrapper" style={{ height: "538px" }}>
          {engagementData.length > 0 ? (
            <>
              <h5 className="chart-title">
                TikTok Engagement Metrics &nbsp;
                <span
                  data-tooltip-id="help-tooltip"
                  style={{ fontSize: "26px" }}
                >
                  <IoIosHelpCircleOutline />
                </span>
                <ReactTooltip
                  id="help-tooltip"
                  place="bottom"
                  content="Helping Text"
                />
              </h5>
              <Bar data={generateChartData()} options={options} />
            </>
          ) : (
            <p>No engagement metrics available.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TikTokEngagementMetrics;
