import React, { useContext, useState } from "react";
import "./progressbar.css";
import { Link, useLocation } from "react-router-dom";
import { FaUser, FaUsers, FaLock, FaBuilding, FaBars } from "react-icons/fa";
import { PiJarLabelFill } from "react-icons/pi";
import { SiFreelancer } from "react-icons/si";
import CommonLoader from "../../../loader/CommonLoader";
import { LoginContext } from "../../../App";
import { hasPermission } from "../../../helperPermissionEditor";
import { FaPhoneVolume } from "react-icons/fa6";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { GiSoundOn } from "react-icons/gi";
import { GiBookCover } from "react-icons/gi";
import { GiBookmark } from "react-icons/gi";
import { GiHeptagram } from "react-icons/gi";
import { FaTags } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { RiSoundModuleLine } from "react-icons/ri";
import { FaTiktok } from "react-icons/fa";

const ProgressBar = () => {
  const { user } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isActive = (path) => location.pathname === path;

  const getLinkClass = (path) => {
    return `link  text-decoration-none ${isActive(path) ? "active-link" : ""}`;
  };

  const activeLinkStyle = {
    backgroundColor: "#f5f5f5",
    color: "#262626",
  };

  return (
    <div className={`ProgressBar ${isSidebarOpen ? "open" : "collapsed"} pt-3`}>
      <button
        className="toggle-button link text-white text-decoration-none"
        onClick={toggleSidebar}
      >
        <FaBars className="me-2" />
        <h5>{isSidebarOpen && "BookTok Tracker"}</h5>
      </button>

      <Link
        className={getLinkClass("/dashboard")}
        to="/dashboard"
        style={isActive("/dashboard") ? activeLinkStyle : {}}
      >
        <FaHome className="me-2" />
        {isSidebarOpen && "Dashboard"}
      </Link>

      {hasPermission(user.permissions, "view-user") && (
        <Link
          className={getLinkClass("/userListing")}
          to="/userListing"
          style={isActive("/userListing") ? activeLinkStyle : {}}
        >
          <FaUser className="me-2" />
          {isSidebarOpen && "Users"}
        </Link>
      )}

      {hasPermission(user.permissions, "view-client") && (
        <Link
          className={getLinkClass("/clientListing")}
          to="/clientListing"
          style={isActive("/clientListing") ? activeLinkStyle : {}}
        >
          <FaBuilding className="me-2" />
          {isSidebarOpen && "Clients"}
        </Link>
      )}

      {hasPermission(user.permissions, "view-account-manager") && (
        <Link
          className={getLinkClass("/accountManager")}
          to="/accountManager"
          style={isActive("/accountManager") ? activeLinkStyle : {}}
        >
          <FaBuilding className="me-2" />
          {isSidebarOpen && "Account Manager"}
        </Link>
      )}

      {hasPermission(user.permissions, "view-role") && (
        <Link
          className={getLinkClass("/rolelist")}
          to="/rolelist"
          style={isActive("/rolelist") ? activeLinkStyle : {}}
        >
          <FaUsers className="me-2" />
          {isSidebarOpen && "Roles"}
        </Link>
      )}

      {hasPermission(user.permissions, "view-permission") && (
        <Link
          className={getLinkClass("/permissionlist")}
          to="/permissionlist"
          style={isActive("/permissionlist") ? activeLinkStyle : {}}
        >
          <FaLock className="me-2" />
          {isSidebarOpen && "Permissions"}
        </Link>
      )}

      {hasPermission(user.permissions, "view-freelancer") && (
        <Link
          className={getLinkClass("/FreelancersListing")}
          to="/FreelancersListing"
          style={isActive("/FreelancersListing") ? activeLinkStyle : {}}
        >
          <SiFreelancer className="me-2" />
          {isSidebarOpen && "Freelancers"}
        </Link>
      )}

      {hasPermission(user.permissions, "view-phone") && (
        <Link
          className={getLinkClass("/PhoneListing")}
          to="/PhoneListing"
          style={isActive("/PhoneListing") ? activeLinkStyle : {}}
        >
          <FaPhoneVolume className="me-2" />
          {isSidebarOpen && "Phone Management"}
        </Link>
      )}

      {hasPermission(user.permissions, "view-phone") && (
        <Link
          className={getLinkClass("/Labels")}
          to="/labels"
          style={isActive("/Labels") ? activeLinkStyle : {}}
        >
          <PiJarLabelFill className="me-2" />
          {isSidebarOpen && "Labels"}
        </Link>
      )}

      {hasPermission(user.permissions, "view-sound") && (
        <Link
          className={getLinkClass("/Sound")}
          to="/sounds"
          style={isActive("/Sounds") ? activeLinkStyle : {}}
        >
          <GiSoundOn className="me-2" />
          {isSidebarOpen && "Sounds"}
        </Link>
      )}

      {hasPermission(user.permissions, "view-book") && (
        <Link
          className={getLinkClass("/Book")}
          to="/books"
          style={isActive("/Books") ? activeLinkStyle : {}}
        >
          <GiBookCover className="me-2" />
          {isSidebarOpen && "Books"}
        </Link>
      )}

      {hasPermission(user.permissions, "view-text") && (
        <Link
          className={getLinkClass("/Text")}
          to="/texts"
          style={isActive("/Texts") ? activeLinkStyle : {}}
        >
          <GiBookmark className="me-2" />
          {isSidebarOpen && "Texts"}
        </Link>
      )}

      {hasPermission(user.permissions, "view-company") && (
        <Link
          className={getLinkClass("/company")}
          to="/company"
          style={isActive("/company") ? activeLinkStyle : {}}
        >
          <HiOutlineOfficeBuilding className="me-2" />
          {isSidebarOpen && "Company"}
        </Link>
      )}
      {hasPermission(user.permissions, "view-hashtag") && (
        <Link
          className={getLinkClass("/HashTag")}
          to="/hashTags"
          style={isActive("/HashTags") ? activeLinkStyle : {}}
        >
          <GiHeptagram className="me-2" />
          {isSidebarOpen && "HashTags"}
        </Link>
      )}

      {["freelancer", "account-manager"].includes(
        localStorage.getItem("userRole")
      ) && (
        <Link
          className={getLinkClass("/freelancerClients")}
          to="/freelancerClients"
          style={isActive("/freelancerClients") ? activeLinkStyle : {}}
        >
          <SiFreelancer className="me-2" />
          {isSidebarOpen && "Freelancer Clients"}
        </Link>
      )}

      {/* {hasPermission(user.permissions, "view-hashtag") && ( */}
      <Link
        className={getLinkClass("/Tag")}
        to="/tags"
        style={isActive("/Tags") ? activeLinkStyle : {}}
      >
        <FaTags className="me-2" />
        {isSidebarOpen && "Tags"}
      </Link>
      {/* )} */}

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
